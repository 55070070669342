<script lang="ts" setup>
import type { Entry, EntryCollection } from "contentful";
import type { TypeArticleSkeleton } from "~/types/contentful";

const props = defineProps<{
  title?: string;
  articleId?: string;
  authorId?: string;
  topicOrSubtopicIds?: string[];
  limit?: number;
}>();
const { data: articlesInTopic } = await useLazyFetch<
  EntryCollection<TypeArticleSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">
>("/api/articles", {
  query: {
    // ...(props.authorId ? { authorIds: props.authorId } : {}),
    ...(props.topicOrSubtopicIds
      ? { topicOrSubtopicIds: props.topicOrSubtopicIds }
      : {}),
    exceptIds: props.articleId,
    ...(props.limit ? { perPage: props.limit } : {}),
  },
  transform: useCircularProtect,
});

const articleUrl = (article: Entry<TypeArticleSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">) =>
  getUrl(article);
</script>

<template>
  <div class="mt-16">
    <h4
      v-if="articlesInTopic?.total"
      class="!mb-10"
    >
      {{ title ?? "Related Content" }}
    </h4>

    <div
      v-for="article in articlesInTopic?.items"
      :key="article.sys.id"
      class="mb-6"
    >
      <NuxtLink
        :to="articleUrl(article)"
        class="flex items-center gap-6 after:hidden"
      >
        <NuxtImg
          v-if="article.fields.image"
          :src="article.fields.image.fields.file?.url"
          :alt="article.fields.image.fields.title"
          :width="100"
          :height="100"
          fit="contain"
          class="!mb-0"
        />
        <h6 class="text-sm font-bold leading-relaxed">
          {{ article.fields.title }}
        </h6>
      </NuxtLink>
    </div>
  </div>
</template>
