import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeDownloadSkeleton } from "./TypeDownload";
import type { TypePageSkeleton } from "./TypePage";
import type { TypeStaffSkeleton } from "./TypeStaff";
import type { TypeSubtopicSkeleton } from "./TypeSubtopic";
import type { TypeTopicSkeleton } from "./TypeTopic";

export type TypeReportFields = {
  title: EntryFieldTypes.Symbol;
  subtitle?: EntryFieldTypes.Symbol;
  slug: EntryFieldTypes.Symbol;
  parent?: EntryFieldTypes.EntryLink<TypePageSkeleton>;
  publishDate?: EntryFieldTypes.Date;
  topicOrSubtopic?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeSubtopicSkeleton | TypeTopicSkeleton>>;
  authors?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeStaffSkeleton>>;
  externalAuthor?: EntryFieldTypes.Symbol;
  content?: EntryFieldTypes.RichText;
  report?: EntryFieldTypes.EntryLink<TypeDownloadSkeleton>;
  metaTitle?: EntryFieldTypes.Symbol;
  metaDescription?: EntryFieldTypes.Text;
};

export type TypeReportSkeleton = EntrySkeletonType<TypeReportFields, "report">;
export type TypeReport<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeReportSkeleton, Modifiers, Locales>;

export function isTypeReport<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeReport<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "report";
}
