<template>
  <form
    class="rounded-xl bg-navy text-center"
    name="subscribe-article"
    netlify
  >
    <input
      type="hidden"
      name="form-name"
      value="subscribe"
    />
    <div class="p-10">
      <h3 class="text-white ">
        Stay updated on the latest US SIF news
      </h3>

      <div class="relative mt-10">
        <input
          id="email-subscribe"
          type="email"
          name="email"
          required
          placeholder="Enter your email"
          class="w-full rounded-md border border-white bg-white p-4 text-base text-charcoal focus:border-blue/80"
        />

        <UiButton
          button-type="primary"
          button-theme="dark"
          class="mt-8 w-full"
        >
          <button
            type="submit"
            class="w-full"
          >
            Subscribe
          </button>
        </UiButton>
      </div>
    </div>
  </form>
</template>
