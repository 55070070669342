<script lang="ts" setup>
import type { Entry } from "contentful";
import { formatDate } from "~/lib/date-time";
import type { TypeStaffSkeleton, TypeSubtopicSkeleton, TypeTopicSkeleton } from "~/types/contentful";

const props = defineProps<{
  title: string;
  subtitle?: string;
  type?: string;
  publishDate?: string;
  authors?: Entry<TypeStaffSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">[];
  externalAuthor?: string;
  topicOrSubtopics?: Entry<
    TypeTopicSkeleton | TypeSubtopicSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >[];
}>();

const date = computed(() =>
  props.publishDate ? formatDate(new Date(props.publishDate).toISOString()) : "",
);
</script>

<template>
  <header class="mb-6 grid grid-cols-12 gap-y-0 text-sm lg:mb-14 lg:gap-y-4">
    <div class="col-span-12">
      <h1>
        {{ title }}
      </h1>
      <div
        v-if="subtitle"
        class=""
      >
        {{ subtitle }}
      </div>
    </div>

    <div class="col-span-12 flex flex-wrap gap-2 text-sm lg:gap-x-4">
      <div class="font-bold">
        {{ type }}
      </div>

      <span class="-mt-1 text-xl font-bold text-blue">|</span>

      <div
        v-if="topicOrSubtopics"
        class="flex flex-row"
      >
        <ArticleTopic
          :topic-or-subtopics="topicOrSubtopics"
          title="Topics:"
        />

        <span class="-mt-1 text-xl font-bold text-blue">|</span>
      </div>

      <div class="flex items-center">
        <NuxtIcon
          name="ussif:calendar"
          class="me-2"
        />
        {{ date }}
      </div>

      <span class="-mt-1 text-xl font-bold text-blue">|</span>

      <!-- temp solution to get around Contentful limit with amount of Content types -->
      <ArticleAuthor
        v-if="authors && authors.length > 0"
        :authors="authors"
        :external-author="externalAuthor"
      />
    </div>
  </header>
</template>
