<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import nodeRenderers from "~/lib/node-renderers.js";
import { isTypeArticle, isTypeReport, isTypePage } from "~/types/contentful";

const page = useLoadedContent();
</script>

<template>
  <div>
    <UiContainer
      v-if="page && page.fields.slug != 'home'"
      class="my-4 lg:mb-0 lg:mt-10"
      no-padding
    >
      <HeaderBlockBreadcrumbs />
      <h1
        v-if="page && isTypePage(page) && page.fields.displayPageTitle"
        class="mt-10"
      >
        {{ page.fields.title }}
      </h1>
    </UiContainer>

    <TemplateArticle
      v-if="page && isTypeArticle(page)"
      :article="page"
    />
    <TemplateReport
      v-else-if="page && isTypeReport(page)"
      :report="page"
    />
    <div v-else-if="page && isTypePage(page) && page.fields.content">
      <RichTextRenderer
        :document="page.fields.content"
        :node-renderers="nodeRenderers"
      />
    </div>
  </div>
</template>
