<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import nodeRenderers from "~/lib/node-renderers.js";
import type { TypeArticle } from "~/types/contentful";

const props = defineProps<{
  article: TypeArticle<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">;
}>();

const topicOrSubtopics = computed(() =>
  props.article.fields.topicOrSubtopic?.filter(
    (topicOrSubtopic): topicOrSubtopic is NonNullable<typeof topicOrSubtopic> => !!topicOrSubtopic,
  ),
);

const relatedArticleIds = computed(() =>
  props.article.fields.relatedContent?.map((item) => item?.sys.id),
);
</script>

<template>
  <UiContainer>
    <div class="article">
      <div class="article__body">
        <div class="grid grid-cols-12">
          <div class="col-span-12 lg:col-span-8">
            <ArticleHeader
              :title="article.fields.title"
              :subtitle="article.fields.subtitle"
              :type="article.fields.articleType?.fields.title"
              :publish-date="article.fields.publishDate"
              :authors="
                article.fields.authors?.filter(
                  (author): author is NonNullable<typeof author> => !!author,
                )
              "
              :external-author="props.article.fields.externalAuthor"
              :topic-or-subtopics="topicOrSubtopics"
            />
          </div>
        </div>
        <div class="article__content">
          <div class="grid grid-cols-12 lg:gap-x-20">
            <div class="col-span-12 lg:col-span-8">
              <div
                v-if="article.fields.image?.fields.file && !article.fields.hideArticleImage"
                class="mb-10"
              >
                <NuxtImg
                  :src="article.fields.image.fields.file.url"
                  :alt="article.fields.image.fields.title"
                  :width="864"
                  :height="440"
                  fit="contain"
                  class="rounded-xl"
                />
                <div
                  v-if="article.fields.image?.fields.description"
                  class="mt-4 text-center text-sm"
                >
                  {{ article.fields.image.fields.description }}
                </div>
              </div>

              <RichTextRenderer
                v-if="article.fields.content"
                :document="article.fields.content"
                :node-renderers="nodeRenderers"
              />

              <p
                v-if="article.fields.articleType?.fields.disclaimer"
                class="my-10 rounded-lg bg-charcoal/5 p-8 text-sm lg:my-20 lg:p-8"
              >
                <span class="me-2 font-bold">Disclaimer:</span>{{ article.fields.articleType?.fields.disclaimer }}
              </p>
            </div>

            <div class="col-span-4 hidden lg:block">
              <div class="sticky top-0">
                <ArticleSubscribe />

                <ArticleRelated
                  v-if="topicOrSubtopics?.length"
                  :article-id="article.sys.id"
                  :topic-or-subtopic-ids="[topicOrSubtopics?.[0]?.sys.id ?? '']"
                  :limit="3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </UiContainer>

  <CarouselArticle
    v-if="article.fields.relatedContent"
    :fields="{ title: 'Related Articles', whiteBackground: true }"
    :related-article-ids="relatedArticleIds"
  />
</template>
