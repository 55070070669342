<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeReport } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers.js";

const props = defineProps<{
  report: TypeReport<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">;
}>();

const topicOrSubtopics = computed(() =>
  props.report.fields.topicOrSubtopic?.filter(
    (topicOrSubtopic): topicOrSubtopic is NonNullable<typeof topicOrSubtopic> =>
      !!topicOrSubtopic,
  ),
);
</script>

<template>
  <UiContainer no-bottom-padding>
    <div class="grid grid-cols-12 gap-y-4">
      <div class="col-span-12 md:col-span-7">
        <ArticleHeader
          :title="report.fields.title"
          :subtitle="report.fields.subtitle"
          type="Reports"
          :publish-date="report.fields.publishDate"
          :authors="report.fields.authors?.filter((author): author is NonNullable<typeof author> => !!author)"
          :external-author="report.fields.externalAuthor"
          :topic-or-subtopics="topicOrSubtopics"
        />
      </div>
      <div
        v-if="report.fields.report"
        v-rellax
        class="col-span-10 col-start-2 md:col-span-3 md:col-start-9"
        data-rellax-speed="1"
        data-rellax-percentage="0.5"
      >
        <CardReport
          :item="report.fields.report"
          header
        />
      </div>
    </div>
  </UiContainer>
  <RichTextRenderer
    :document="report.fields.content"
    :node-renderers="nodeRenderers"
  />
</template>
